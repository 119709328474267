import { ChangeEvent } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { IValuesFilterComissaoProdutor } from "src/Contexts/comissoes/ComissoesTypes";
import {
  valuesDefaultFilterComissaoCorretora,
  valuesDefaultFilterComissaoProdutor,
} from "src/Contexts/comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { AutoCompleteOperadoraFilter } from "src/Pages/components/AutoCompleted/Filters/AutoCompleteOperadora";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { useHttpTableComissaoProdutor } from "../table/Hooks/useHttpTableComissaoProdutor";
import { AutoCompleteProdutor } from "src/Pages/components/AutoCompleted/AutoCompleteProdutor";

export const FilterSidebarComissaoProdutor = () => {
  const { handleGet } = useHttpTableComissaoProdutor();

  const {
    valueAutoCompOperadoraFilter,
    setValueAutoCompOperadoraFilter,
    valueAutoProdutor,
    setValueAutoProdutor,
  } = UseAutoCompletedAll();

  const { valuesFilterComissaoProdutor, setValuesFilterComissaoProdutor } =
    useContextComissoes();

  const handleChange =
    (prop: keyof IValuesFilterComissaoProdutor) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesFilterComissaoProdutor({
        ...valuesFilterComissaoProdutor,
        [prop]: event.target.value,
      });
    };

  const ClearFilter = async () => {
    setValuesFilterComissaoProdutor(valuesDefaultFilterComissaoProdutor);
    setValueAutoCompOperadoraFilter({ id: "", label: "" });
    setValueAutoProdutor({ id: "", label: "" });

    await handleGet({
      isActive: valuesDefaultFilterComissaoCorretora.isActive,
    });
  };

  const FilterSearch = async () => {
    await handleGet({
      codeOperadora: valueAutoCompOperadoraFilter.id,
      isActive: valuesFilterComissaoProdutor.isActive,
      name: valuesFilterComissaoProdutor.name,
      produtorCode: valueAutoProdutor.id,

      tipo_produtor: valuesFilterComissaoProdutor.tipo_produtor,
      funcao_produtor: valuesFilterComissaoProdutor.funcao_produtor,
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <Box sx={{ "& > *": { marginBottom: "1rem" } }}>
        <AppTextField
          fullWidth
          label="Nome da comissão"
          sxStyle={{ opacity: "", pointerEvents: "auto", mb: 2 }}
          value={valuesFilterComissaoProdutor.name}
          variant="outlined"
          onChange={handleChange("name")}
        />

        <AutoCompleteOperadoraFilter
          fullWidth
          label="Operadora"
          variant="outlined"
        />

        <AutoCompleteProdutor fullWidth label="Produtor" variant="outlined" />

        <AppTextField
          variant="outlined"
          select
          id="Função_Produtor"
          label="Função Produtor"
          sxStyle={{ opacity: "", pointerEvents: "auto", mb: 2, width: "47%" }}
          value={valuesFilterComissaoProdutor?.funcao_produtor}
          onChange={handleChange("funcao_produtor")}
        >
          <MenuItem value="ALL">Todos</MenuItem>
          <MenuItem value="CORRETOR">Corretor</MenuItem>
          <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
          <MenuItem value="GERENTE">Gerente</MenuItem>
        </AppTextField>

        <AppTextField
          variant="outlined"
          fullWidth
          select
          id="Type"
          label="Tipo"
          sxStyle={{ opacity: "", pointerEvents: "auto", mb: 2, width: "47%" }}
          value={valuesFilterComissaoProdutor.tipo_produtor}
          onChange={handleChange("tipo_produtor")}
        >
          <MenuItem value="ALL">Todos</MenuItem>
          <MenuItem value="SALAO">Salão</MenuItem>
          <MenuItem value="REPASSE">Repasse</MenuItem>
          <MenuItem value="FREE_LANCE">Free lance</MenuItem>
          <MenuItem value="CONECTOR">Conector</MenuItem>
          <MenuItem value="ADMINISTRATIVO">Administrativo</MenuItem>
          <MenuItem value="INTERNO">Interno</MenuItem>
          <MenuItem value="HOME">Home</MenuItem>
          <MenuItem value="START">Start</MenuItem>
          <MenuItem value="MOVE">Move</MenuItem>
          <MenuItem value="MASTER">Master</MenuItem>
        </AppTextField>

        <AppTextField
          fullWidth
          select
          id="Tem_recibo"
          label="Comissao ativa ?"
          sxStyle={{ opacity: "", pointerEvents: "auto" }}
          value={valuesFilterComissaoProdutor.isActive}
          variant="outlined"
          onChange={handleChange("isActive")}
        >
          <MenuItem value="ALL">Não informar</MenuItem>
          <MenuItem value="TRUE">SIM</MenuItem>
          <MenuItem value="FALSE">NÃO</MenuItem>
        </AppTextField>
      </Box>
    </ModalFilterCore>
  );
};
