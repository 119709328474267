import { useEffect } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useGetCep } from "src/shared/Hooks/useGetCep";

export const UseGetCEPContratos = () => {
  const { handleGetCEP } = useGetCep();
  const { id } = useConfigPageContext();

  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  useEffect(() => {
    if (valuesInputsContrato.cep?.length === 9 && !id) {
      const handleGet = async () => {
        const { erro, bairro, localidade, logradouro, uf } = await handleGetCEP(
          valuesInputsContrato.cep
        );

        if (erro) {
          setValuesInputsContrato((prevEvent) => ({
            ...prevEvent,
            uf: "",
            bairro: "",
            cidade: "",
            endereco: "",
          }));
        } else {
          setValuesInputsContrato((prevEvent) => ({
            ...prevEvent,
            uf,
            bairro,
            endereco: logradouro,
            cidade: localidade,
          }));
        }
      };

      handleGet();
    }
  }, [valuesInputsContrato.cep]);

  useEffect(() => {
    if (
      valuesInputsContrato.cep?.length === 9 &&
      id &&
      !valuesInputsContrato.endereco
    ) {
      const handleGet = async () => {
        const { erro, bairro, localidade, logradouro, uf } = await handleGetCEP(
          valuesInputsContrato.cep
        );

        if (erro) {
          setValuesInputsContrato((prevEvent) => ({
            ...prevEvent,
            uf: "",
            bairro: "",
            cidade: "",
            endereco: "",
          }));
        } else {
          setValuesInputsContrato((prevEvent) => ({
            ...prevEvent,
            uf,
            bairro,
            endereco: logradouro,
            cidade: localidade,
          }));
        }
      };

      handleGet();
    }
  }, [valuesInputsContrato.cep]);
};
